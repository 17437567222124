exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-london-apartment-tsx": () => import("./../../../src/pages/projects/london-apartment.tsx" /* webpackChunkName: "component---src-pages-projects-london-apartment-tsx" */),
  "component---src-pages-projects-london-house-tsx": () => import("./../../../src/pages/projects/london-house.tsx" /* webpackChunkName: "component---src-pages-projects-london-house-tsx" */),
  "component---src-pages-projects-london-mews-house-tsx": () => import("./../../../src/pages/projects/london-mews-house.tsx" /* webpackChunkName: "component---src-pages-projects-london-mews-house-tsx" */),
  "component---src-pages-projects-london-townhouse-tsx": () => import("./../../../src/pages/projects/london-townhouse.tsx" /* webpackChunkName: "component---src-pages-projects-london-townhouse-tsx" */),
  "component---src-pages-projects-riverside-apartment-tsx": () => import("./../../../src/pages/projects/riverside-apartment.tsx" /* webpackChunkName: "component---src-pages-projects-riverside-apartment-tsx" */),
  "component---src-pages-projects-townhouse-tsx": () => import("./../../../src/pages/projects/townhouse.tsx" /* webpackChunkName: "component---src-pages-projects-townhouse-tsx" */),
  "component---src-pages-services-design-services-tsx": () => import("./../../../src/pages/services/design-services.tsx" /* webpackChunkName: "component---src-pages-services-design-services-tsx" */),
  "component---src-pages-services-home-staging-services-tsx": () => import("./../../../src/pages/services/home-staging-services.tsx" /* webpackChunkName: "component---src-pages-services-home-staging-services-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-project-management-services-tsx": () => import("./../../../src/pages/services/project-management-services.tsx" /* webpackChunkName: "component---src-pages-services-project-management-services-tsx" */)
}

